import { NavLink } from "react-router-dom";
import { useEffect } from "react";

import Logo from "../images/logo.jpg";
import Cart from "./Cart";
import Footer from "./Footer";
import "../../style/About.css";
import SearchBox from "./Searchbox";
import NavBar from "./Navbar";
import ISOcer from "../images/isoCertified.png";

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
            <div className="aboutMain" style={{ minHeight: "180px", paddingBottom: "30px" }}>

                {/* Navbar */}
                <NavBar />

            </div>

            {/* About content */}

            <div className="aboutCntxtMain">
                <div className="aboutCntxt">
                    <div className="abtImgDiv">
                        <img src={Logo} alt="" />
                    </div>
                    <div className="abtContentDiv">
                        <h2 style={{ borderLeft: "7px solid hsla(55, 90%, 67%, 1)", paddingLeft: "20px", position: "relative", left: "10px", margin: "20px auto" }} >ABOUT US</h2>
                        <p>Welcome to HPZ Hardware, a pioneering enterprise established in 1995 in Hyderabad by a team of experienced hardware professionals. Our journey began with a straightforward yet profound mission: to provide high-quality hardware products that seamlessly integrate with both the art and science of construction and maintenance, ensuring lasting solutions for professionals and DIY enthusiasts alike.</p>
                        <p>At HPZ Hardware, we combine innovation with expertise, striving to offer a comprehensive selection of state-of-the-art products that empower craftsmen and builders to elevate their work standards. With an unwavering commitment to excellence, we constantly push the boundaries of hardware technology to equip our customers with the tools they need to stay ahead in their projects.</p>
                    </div>
                </div>
                <div className="abtContentDiv" style={{ width: "100%", maxWidth: "1222px" }}>
                    <p style={{ padding: "2px" }}>Our ethos centers on the belief that every hardware solution should not only meet but surpass expectations. Therefore, we meticulously design and develop our products with a focus on long-term compatibility, durability, and performance, ensuring optimal results and satisfaction for our customers.</p>
                    <p style={{ padding: "2px" }}>Driven by a passion for innovation and a dedication to quality, we are committed to serving the hardware community both nationally and internationally. Our goal is not just to meet industry standards but to redefine them, setting new benchmarks in hardware solutions and advancing the field of construction and maintenance.</p>
                    <p style={{ padding: "2px" }} >We take immense pride in being an ISO 9001:2015 Certified Company, a testament to our steadfast commitment to quality management and customer satisfaction. This globally recognized certification reflects our dedication to maintaining the highest standards in product design, manufacturing, and delivery. At HPZ Hardware, quality is not just a goal; it is an integral part of our culture and operations.</p>
                </div>

                <div className="aboutCntxt">
                    <div className="abtContentDiv">
                        <h2 style={{ borderLeft: "7px solid hsla(55, 90%, 67%, 1)", paddingLeft: "20px", position: "relative", left: "10px", margin: "20px auto" }} >What is ISO 9001:2015 Certification?</h2>
                        <p style={{ paddingRight: "20px", marginLeft: "-8px" }}>ISO 9001:2015 is an internationally recognized standard for quality management systems (QMS). It sets the framework for businesses to consistently meet customer requirements and improve processes over time. Companies that achieve this certification demonstrate their ability to deliver products and services that meet regulatory and customer standards while ensuring continual improvement.</p>
                    </div>
                    <div className="abtImgDiv">
                        <img src={ISOcer} alt="" />
                    </div>
                </div>
                <div className="abtContentDiv" style={{ width: "100%", maxWidth: "1222px" }}>
                    <p style={{ padding: "2px" }} >The ISO 9001:2015 certification focuses on key principles such as customer focus, leadership, engagement of people, process approach, and evidence-based decision-making. It is a mark of trust and reliability, helping companies enhance efficiency, reduce errors, and foster customer satisfaction. For our customers, this certification ensures that HPZ Hardware operates with a systematic approach to quality, delivering only the best products that stand the test of time.</p>
                    <p style={{ padding: "2px" }} >Driven by a passion for innovation and a dedication to quality, we are committed to serving the hardware community both nationally and internationally. Our goal is not just to meet industry standards but to redefine them, setting new benchmarks in hardware solutions and advancing the field of construction and maintenance.</p>
                    <p style={{ padding: "2px" }} >As we continue our journey towards excellence, we invite you to explore our range of technologically advanced products and experience the HPZ Hardware difference. Together, let’s build a future where hardware excellence knows no limits.</p>
                </div>
            </div>

            {/* footer */}
            <Footer />

        </>
    )
}

export default About;