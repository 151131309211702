import { NavLink } from "react-router-dom";
import { useEffect } from "react";

import Logo from "../images/logo.jpg";
import Cart from "./Cart";
import Footer from "./Footer";
import "../../style/About.css";
import SearchBox from "./Searchbox";
import NavBar from "./Navbar";

const SRCPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (<>
        <div className="aboutMain" style={{minHeight:"180px" , paddingBottom:"30px"}}>
        
            {/* Navbar */}
            <NavBar />

        </div>

        {/*content */}

        <div className="privacyMain">
            <div className="privacyContext" >

                {/* First Part */}
                <h2>Shipping, Refund & Cancellation Policy</h2>
                <h4>Note : Delivery Time : 3 to 7 Days.</h4>

                <p>Please <span>contact us</span> regarding any questions regarding this statement.</p>

                <ol>
                    <li>If you do not accept below return policy, please do not order the product.</li>
                    <li>All materials has to be as it is in case of returns.</li>
                    <li>Pack the entire kit as it is and Courier it to our address (HPZ Worldwide PVT. LTD. , 3-1-1250, 3rd & 4th floor, Seetarambagh, Hyderabad, Telangana 500001). You have to bear courier charges.</li>
                    <li>You should return and send courier receipt to support@hpzhardware.in within 3 working days of receiving your kit.</li>
                    <li>Upon receiving the all materials safely, we will raise Refund.</li>
                    <li>Amount will be refunded within 7 to 15 working days depending on your bank.</li>
                    <li>We do not have any affiliates or resellers. Make sure you order products only on this website (hpzhardware.in).</li>
                </ol>

                <p>But we rarely have any returns, since our products are Reliable, Tested and Innovative to make their life easier. But still if you are not satisfied, you will get your money back.</p>

            </div>
        </div>

        {/* footer */}
        <Footer />
    </>)

}

export default SRCPolicy;